import { convertToQueryParams } from '../Utils/helper/commonUtils';
import axios from './httpRequest';

class CultServices {
	// auth
	login = async ({ params = {}, data = {}, config = {} } = {}) => {
		return axios.post(`/auth/login`, data, { showLoader: true, ...config });
	};
	// ----

	// calling
	createCall = async ({ params = {}, data = {}, config = {} } = {}) => {
		return axios.post(`/call`, data, { showLoader: true, ...config });
	};
	// ------

	// employees
	getEmployeesList = async ({ params: { companyId }, data = {}, config = {} } = {}) => {
		let res = await axios.get(`/users?companyId=${companyId}`, data, { showLoader: true, ...config });
		return res.data;
	};

	createUser = async ({ data = {}, config = {} } = {}) => {
		return axios.post(`/users`, data, { showLoader: true, ...config });
	};

	updateUser = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.put(`/users/${id}`, data, { showLoader: true, ...config });
	};

	updatePhoneNumber = async ({ params: { userId }, data = {}, config = {} } = {}) => {
		return axios.put(`/user/phone/${userId}`, data, { showLoader: true, ...config });
	};

	deleteUser = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.delete(`/users/${id}`, data, { showLoader: true, ...config });
	};

	getUserById = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.get(`/users/${id}`, data, { showLoader: true, ...config });
	};

	getAllUsersPaginated = async ({ params: { page, companyId, filterQuery }, data = {}, config = {} } = {}) => {
		return axios.get(
			`/users/pagination?page=${page}&limit=200&companyId=${companyId}&${convertToQueryParams(filterQuery)}`,
			data,
			{
				showLoader: true,
				...config,
			},
		);
	};

	importUserExcel = async ({ data = {}, config = {} } = {}) => {
		return axios.post(`/users/import`, data, { showLoader: true, ...config });
	};
	// ----------

	// campaign
	createCampaign = async ({ data = {}, config = {} } = {}) => {
		return axios.post(`/compaign`, data, { showLoader: true, ...config });
	};

	updateCampaign = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.put(`/compaign/${id}`, data, { showLoader: true, ...config });
	};

	updateCampaignStatus = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.put(`/compaign/update-status/${id}`, data, { showLoader: true, ...config });
	};

	getCampaignList = async ({ params: { companyId }, data = {}, config = {} } = {}) => {
		let res = await axios.get(`/compaign?companyId=${companyId}`, data, { showLoader: true, ...config });
		return res.data;
	};

	getCampaignById = async ({ params: { id }, data = {}, config = {} } = {}) => {
		let res = await axios.get(`/compaign/${id}`, data, { showLoader: true, ...config });
		return res.data;
	};

	deleteCampaign = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.delete(`/compaign/${id}`, data, { showLoader: true, ...config });
	};

	getAllModelAssistanst = async ({ params: { companyId }, config = {} } = {}) => {
		return axios.get(`/assistant/default?companyId=${companyId}`, { showLoader: true, ...config });
	};

	getFilterValues = async ({ params: { companyId }, config = {} } = {}) => {
		return axios.get(`/users/filter-value?companyId=${companyId}`, { showLoader: true, ...config });
	};

	scheduleCampaign = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.put(`/compaign/compaign-user/${id}`, data, { showLoader: true, ...config });
	};

	getCampaignUserDetails = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.get(`/compaign/user/${id}`, data, { showLoader: true, ...config });
	};

	extendCampaign = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.put(`/compaign/scheduling/${id}`, data, { showLoader: true, ...config });
	};
	// --------

	// dashboard

	getDashboardStats = async ({ data = {}, config = {} } = {}) => {
		return axios.post(`/dashboard`, data, { showLoader: true, ...config });
	};

	// ----------

	getContextInsigts = async ({ params: { companyId }, config = {} } = {}) => {
		return axios.get(`/reporting-context?company=${companyId}`, { showLoader: true, ...config });
	};

	getCallIssues = async ({ params: { companyId, users = [], campaigns = [] }, config = {} } = {}) => {
		let usersQuery = '';
		if (users.length > 0) {
			usersQuery = `&userIds=${users?.join(',')}`;
		}
		let campaignQuery = '';
		if (campaigns.length > 0) {
			campaignQuery = `&compaignId=${campaigns?.join(',')}`;
		}
		return axios.get(`/call-issues?company=${companyId}${usersQuery}${campaignQuery}`, { showLoader: true, ...config });
	};

	getCriticalRisks = async ({ params: { companyId }, config = {} } = {}) => {
		return axios.get(`/critical-risk-alert?company=${companyId}`, { showLoader: true, ...config });
	};

	getCallRecordings = async ({ params: { companyId, users = [], campaigns = [] }, config = {} } = {}) => {
		let usersQuery = '';
		if (users.length > 0) {
			usersQuery = `&userIds=${users?.join(',')}`;
		}
		let campaignQuery = '';
		if (campaigns.length > 0) {
			campaignQuery = `&compaignId=${campaigns?.join(',')}`;
		}
		return axios.get(`/call-recording?company=${companyId}${usersQuery}${campaignQuery}`, { showLoader: true, ...config });
	};

	getCampaignInsights = async ({ params: { companyId, campaigns = [] }, config = {} } = {}) => {
		let campaignQuery = '';
		if (campaigns.length > 0) {
			campaignQuery = `&campaignId=${campaigns?.join(',')}`;
		}
		return axios.get(`/campaign-insights?company=${companyId}${campaignQuery}`, { showLoader: true, ...config });
	};

	getActionalbleInsigts = async ({ params: { companyId }, config = {} } = {}) => {
		return axios.get(`/actionable-insights?company=${companyId}`, { showLoader: true, ...config });
	};

	addComment = async ({ data = {}, config = {} } = {}) => {
		return axios.post(`/compaign/compaign-user/comment`, data, { showLoader: true, ...config });
	};

	// company
	updateCompany = async ({ params: { id }, data = {}, config = {} } = {}) => {
		return axios.put(`/company/${id}`, data, { showLoader: true, ...config });
	};
}

const CultService = new CultServices();

export default CultService;
