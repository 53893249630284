import {
	Button,
	Dialog,
	IconButton,
	Link,
	Modal,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import { MdOutlineMoreTime, MdOutlineStopCircle } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import { PATH_NAME } from '../../Routes/Pathname';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import DotChip from '../../Components/DotChip';
import UsersList from '../../Components/UsersList';
import SearchBar from '../../Components/SearchBar';
import CreateCampaignModal from './Components/CreateCampaignModal';
import moment from 'moment/moment';
import CultService from '../../Services/CultService';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import toast from 'react-hot-toast';
import PageLoader from '../../Components/PageLoader';
import { AddIcon, DeleteIcon, InfoIcon } from '../../Components/Icons/Icons';
import DeleteConfirm from '../../Components/DeleteConfirm';
import { CAMPAIGN_STATUS } from '../../Utils/helper/AppConstants';
import { useSelector } from 'react-redux';
import { filterBySearch } from '../../Utils/helper/commonUtils';
import ExtendCampaignModal from './Components/ExtendCampaignModal';

export default function CampaignList() {
	const navigate = useNavigate();
	const location = useLocation();
	const [list, setList] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [stopModal, setStopModal] = useState(false);
	const [search, setSearch] = useState('');
	const [showExtend, setShowEntend] = useState(false);
	const company = useSelector((state) => state?.app?.company);

	const getList = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCampaignList({ params: { companyId: company?._id } });
			if (res.status == 200) {
				setList(res.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteCampaign = async () => {
		setDeleting(true);
		try {
			const res = await CultService.deleteCampaign({ params: { id: deleteModal } });
			if (res.status == 200) {
				setDeleteModal(false);
				getList();
			}
		} catch (err) {
		} finally {
			setDeleting(false);
		}
	};

	const handleStopCampaign = async () => {
		setDeleting(true);
		try {
			const res = await CultService.updateCampaignStatus({
				params: { id: stopModal },
				data: { status: CAMPAIGN_STATUS?.stopped?.code },
			});
			if (res.status == 200) {
				setStopModal(false);
				getList();
			}
		} catch (err) {
		} finally {
			setDeleting(false);
		}
	};

	const isExtendable = (company) => {
		let currentEndDate = company?.scheduling?.endDate;
		let currentEndTime = company?.scheduling?.endTime;
		let current = moment(currentEndDate + currentEndTime, 'YYYY-MM-DDHH:mm:ss');
		const diffInHours = current.diff(moment().format(), 'H');
		if (diffInHours > 24) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (company?._id) getList();
	}, [company]);

	useEffect(() => {
		if (location?.state?.create) {
			setOpen(true);
		}
	}, []);

	return (
		<Stack>
			<PageHeading
				title="Campaigns"
				subTitle="Create, track, manage your campaigns"
				renderRight={
					<Stack direction="row" alignItems="center" gap={2}>
						{/* <Button startIcon={<RiDownloadCloud2Line />} color="secondary" variant="outlined">
							Export
						</Button> */}
						<Button
							startIcon={<AddIcon color={AppColors.WHITE} />}
							onClick={() => setOpen(true)}
							color="primary"
							variant="contained"
						>
							Create Campaign
						</Button>
					</Stack>
				}
			/>

			<Stack mt={4} mb={2} direction="row" alignItems="center" justifyContent="space-between">
				{/* <Button variant="outlined" color="secondary" startIcon={<MdFilterList />}>
					More Filters
				</Button> */}

				<SearchBar seaarch={search} setSearch={setSearch} width={340} />
			</Stack>

			<TableContainer sx={{ height: 'calc(100vh - 186px)' }}>
				<Table stickyHeader>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell>Campaign Name</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Target Audience</TableCell>
						<TableCell>Start/End Date</TableCell>
						<TableCell />
					</TableHead>
					<TableBody>
						{filterBySearch(list, search, ['basicDetails.name', 'basicDetails.description'])?.map((c, index) => (
							<TableRow key={index}>
								<TableCell>
									<Link
										sx={{ ':hover': { color: AppColors.TEXT_PRIMARY } }}
										color={AppColors.TEXT_PRIMARY}
										onClick={() => navigate(generatePath(PATH_NAME.CAMPAIGN_DETAILS, { id: c?._id }))}
										variant="subtitle1"
									>
										{c?.basicDetails?.name}
									</Link>
									{c?.basicDetails?.description && (
										<Stack direction="row" alignItems="center" gap={0.5}>
											<Typography
												sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 280 }}
												variant="subtitle2"
											>
												{c?.basicDetails?.description}
											</Typography>
											<Tooltip title={c?.basicDetails?.description}>
												<Stack>
													<InfoIcon />
												</Stack>
											</Tooltip>
										</Stack>
									)}
								</TableCell>
								<TableCell>{<DotChip color={CAMPAIGN_STATUS[c?.status]?.color} label={c?.status} />}</TableCell>
								<TableCell>
									<UsersList users={c?.compaignUser?.map((i) => i.userId)} />
								</TableCell>
								<TableCell>
									<Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} variant="subtitle1">
										{moment(c?.scheduling?.startDate).format('ll')}
									</Typography>
									<Typography variant="subtitle2">{moment(c?.scheduling?.endDate).format('ll')}</Typography>
								</TableCell>

								<TableCell>
									<Stack direction="row" alignItems="center" gap={1}>
										{/* stop */}
										{c?.status === CAMPAIGN_STATUS?.live?.code && (
											<IconButton onClick={() => setStopModal(c._id)} size="small">
												<MdOutlineStopCircle size={22} color={AppColors.TEXT_TERTIARY} />
											</IconButton>
										)}

										{/* edit */}
										{c?.status === CAMPAIGN_STATUS?.draft?.code && (
											<IconButton onClick={() => navigate(PATH_NAME.CAMPAIGN_EDIT, { state: { campaign: c } })} size="small">
												<FiEdit2 size={18} color={AppColors.TEXT_TERTIARY} />
											</IconButton>
										)}

										{/* delete */}
										{(c?.status === CAMPAIGN_STATUS?.draft?.code || c?.status === CAMPAIGN_STATUS?.stopped?.code) && (
											<IconButton onClick={() => setDeleteModal(c._id)} size="small">
												<DeleteIcon size={22} color={AppColors.TEXT_TERTIARY} />
											</IconButton>
										)}

										{/* extend */}
										{isExtendable(c) && c?.status === CAMPAIGN_STATUS?.live?.code && (
											<IconButton onClick={() => setShowEntend(c._id)} size="small">
												<MdOutlineMoreTime size={22} color={AppColors.TEXT_TERTIARY} />
											</IconButton>
										)}
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Modal type="blur" open={open}>
				<CreateCampaignModal open={open} setOpen={setOpen} fetch={getList} />
			</Modal>

			<DeleteConfirm
				loading={deleting}
				open={stopModal}
				onDelete={handleStopCampaign}
				onClose={() => setStopModal(false)}
				title="Stop Campaign?"
				subTitle="Are you sure you want to stop this campaign?"
			/>

			<DeleteConfirm
				loading={deleting}
				open={deleteModal}
				onDelete={handleDeleteCampaign}
				onClose={() => setDeleteModal(false)}
				title="Delete Campaign?"
				subTitle="Are you sure you want to delete this campaign?"
			/>

			<Modal open={Boolean(showExtend)}>
				<ExtendCampaignModal id={showExtend} onClose={() => setShowEntend(false)} fetch={getList} />
			</Modal>

			{loading && <PageLoader />}
		</Stack>
	);
}
