import { Stack, Typography } from '@mui/material';
import { Button, DatePicker } from 'antd';
import React, { useState } from 'react';
import { MdAccessTime } from 'react-icons/md';
import { AppColors } from '../../../Utils/helper/AppColors';
import { FiCalendar } from 'react-icons/fi';
import dayjs from 'dayjs';
import moment from 'moment';
import CultService from '../../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

export default function ExtendCampaignModal({ onClose, fetch, id }) {
	const [data, setData] = useState({});

	const handleSubmit = async () => {
		try {
			let res = await CultService.extendCampaign({ params: { id }, data });
			if (res.status == 200) {
				onClose();
				fetch();
			}
		} catch (err) {
			toast.error(err.response?.data?.error || HelperTexts.SOMETHING_WENT_WRONG);
		}
	};

	return (
		<Stack p={2} sx={style} minWidth={400} gap={3}>
			<Stack>
				<Typography variant="body1">Extend Campaign</Typography>
				<Typography variant="subtitle2">Select a new end date and time for the campaign</Typography>
			</Stack>

			<Stack direction="row" gap={2}>
				<DatePicker
					defaultValue={data?.endDate ? dayjs(data?.endDate, 'YYYY-MM-DD') : null}
					onChange={(e) => setData({ ...data, endDate: moment(new Date(e)).format('YYYY-MM-DD') })}
					suffixIcon={<FiCalendar color="#667085" size={18} />}
					getPopupContainer={(node) => node.parentNode}
					placeholder="End Date"
					allowClear={false}
					minDate={dayjs(new Date())}
				/>
				<DatePicker
					needConfirm={false}
					minuteStep={15}
					showSecond={false}
					defaultValue={data?.endTime ? dayjs(data?.endTime, 'HH:mm:ss') : null}
					onChange={(e) => setData({ ...data, endTime: moment(new Date(e)).format('HH:mm:ss') })}
					suffixIcon={<MdAccessTime color="#667085" size={18} />}
					getPopupContainer={(node) => node.parentNode}
					picker="time"
					placeholder="End Time"
					allowClear={false}
				/>
			</Stack>

			<Stack direction="row" gap={2} justifyContent="flex-end">
				<Button onClick={onClose} size="middle" type="default">
					Cancel
				</Button>
				<Button onClick={handleSubmit} disabled={!data?.endDate || !data?.endTime} size="middle" type="primary">
					Confirm
				</Button>
			</Stack>
		</Stack>
	);
}
